@import "abstracts/_abstracts";
.ButtonBase {
	$block: &;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	transition: var(--animationBase);
	background: var(--colorButtonBg);
	cursor: pointer;
	color: #fff;
	padding: var(--sizeButtonPadding);
	font-size: var(--sizeButtonFont);
	text-decoration: none;
	border: none;
	outline: 0;
	font-weight: 700;

	border-radius: 0.5rem;
	font-size: 1rem;
	line-height: 1.5;
	padding: 0.5rem 1rem;
	color: var(--colorThemeAccent);
	gap: 1rem;

	@include on-event {
		background: lightness(var(--colorBrand), 0.8);
		text-decoration: none;
		color: #fff;
	}

	&-icon {
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 100%;
		height: auto;
		width: 2.5rem;
		aspect-ratio: 1 / 1;
		background-color: rgba(255, 255, 255, 0.30);
		color: var(--colorThemeAccent);

		svg {
			width: 1.5rem;
			height: auto;
		}
	}

	// SIZES
	&--lg {

	}

	&--md {

	}

	&--sm {

	}

	&:disabled {
		background: var(--colorTextSubtle);
		opacity: .6;
		pointer-events: none;
	}

	// STYLES
	&--primary { /* IS DEFAULT STYLING ABOVE */}

	&--secondary {
		background-color: var(--colorThemeAccent);
		border: 1px solid var(--colorOutlines);
		color: var(--colorText);

		@include on-event {
			background: lightness(var(--colorThemeAccent), 0.95);
			text-decoration: none;
			color: var(--colorText);
		}

		#{$block}-icon {
			background-color: var(--colorOutlines);
			color: var(--colorText);
		}
	}

	&--ghost {
		background: transparent;
		color: var(--colorBrand);
		border: 1px solid var(--colorBrand);

		&:disabled {
			background: transparent;
			color: var(--colorTextSubtle);
			border-color: var(--colorTextSubtle);
		}

		@include on-event {
			background: var(--colorBrand);
		}
	}

	&--danger {
		background: transparent;
		color: var(--colorErrorsDefault);
		border: 1px solid var(--colorErrorsDefault);

		&:disabled {
			background: transparent;
			color: var(--colorTextSubtle);
			border-color: var(--colorTextSubtle);
		}

		@include on-event {
			background: var(--colorErrorsDefault);
		}
	}

	&--lite{
		background: transparent;
		color: #000;
		border: 1px solid #888888;

		@include on-event {
			background: var(--colorBrand);
			border-color: var(--colorBrand);
		}
	}
}
